import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import 'amfe-flexible';
import { setRem } from './utils/rem'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
const app = createApp(App)

setRem(375, 37.5);
app.use(ElementPlus)
app.mount('#app')
    