<template>
  <div class="page-game">
    <div class="page-info-title">
      <span class="label">Segurança dos dados</span>
      <span class="icon" @click="bt"><img src="../assets/right.svg" alt="" /></span>
    </div>
    <div class="game-list" @click="bt">
      <span class="game-item">
        <span class="game-logo" ><img src="../assets/game1.webp" alt="" /></span>
        <span class="game-info">
          <span class="game-name">Cash Craze: Casino Slots Games</span>
          <span class="game-desc">Casual Joy Games</span>
          <span class="game-score">4,5</span>
        </span>
      </span>
      <span class="game-item" @click="bt">
        <span class="game-logo"><img src="../assets/game2.webp" alt="" /></span>
        <span class="game-info">
          <span class="game-name">Diamond Slot - Slot Game</span>
          <span class="game-desc">International Games System Co., Ltd.</span>
          <span class="game-score">3,9</span>
        </span>
      </span>
      <span class="game-item" @click="bt">
        <span class="game-logo"><img src="../assets/game3.webp" alt="" /></span>
        <span class="game-info">
          <span class="game-name">Infinity Slots - Casino Games</span>
          <span class="game-desc">Murka Games Limited</span>
          <span class="game-score">4,5</span>
        </span>
      </span>
      <span class="game-item" @click="bt">
        <span class="game-logo"><img src="../assets/game4.webp" alt="" /></span>
        <span class="game-info">
          <span class="game-name">Bônus Bingo Cassino-TaDa Jogo</span>
          <span class="game-desc">FUFAFA TECHNOLOGY LTD CO.</span>
          <span class="game-score">4,4</span>
        </span>
      </span>
      <span class="game-item" @click="bt">
        <span class="game-logo"><img src="../assets/game5.webp" alt="" /></span>
        <span class="game-info">
          <span class="game-name">Jackpot Magic Slots</span>
          <span class="game-desc">Big Fish Games</span>
          <span class="game-score">2,6</span>
        </span>
      </span>
      <span class="game-item" @click="bt">
        <span class="game-logo"><img src="../assets/game6.webp" alt="" /></span>
        <span class="game-info">
          <span class="game-name">Charge Buffalo Slot-TaDa Jogos</span>
          <span class="game-desc">FUFAFA TECHNOLOGY LTD CO.</span>
          <span class="game-score">4,5</span>
        </span>
      </span>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance, defineComponent } from "vue";
export default defineComponent({
  name: "",
  components: {},
  props: {},
  setup(props, { emit }) {
    const state = reactive({});
    const bt = () => {
      emit("func");
    };
    return { ...toRefs(state), bt };
  },
});
</script>
  <style>
.page-game {
  padding-top: 20px;
  flex-direction: column;
}
</style>
  