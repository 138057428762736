<template>
  <div class="max">
    <div class="mask" v-if="inpwa">
      <img
        class="mask_img_tips-install"
        src="../assets/tips-install.jpg"
        alt=""
      />
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance, defineComponent } from "vue";
export default defineComponent({
  name: "",
  components: {},
  setup() {
    const state = reactive({
      inpwa: false,
    });
    if (getPWADisplayMode() === "standalone") {
      state.inpwa = true;
      console.log("standalone");
    } else {
      console.log(1231231212);
    }
    function getPWADisplayMode() {
      let isStandalone = window.matchMedia(
        "(display-mode: standalone)"
      ).matches;
      if (document.referrer.startsWith("android-app://")) {
        return "app";
      } else if (navigator.standalone || isStandalone) {
        return "standalone";
      }
      return "browser";
    }
    return { ...toRefs(state) };
  },
});
</script>
<style>
.max {
  /* position: relative; */
}
.mask {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  /* background: rgba(0, 0, 0, 0.5); */
  /* background: url(../assets/tips-install.jpg) no-repeat; */
  /* background-size: 100%; */
}

.mask_img_tips-install {
  width: 100%;
}
</style>