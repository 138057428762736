<template>
  <div class="page-desc">
    <div class="desc-item">
      <span class="label"
        >4,9
        <i class="google-material-icons">star</i>
      </span>
      <span class="value">46K avaliações</span>
    </div>
    <div class="desc-item">
      <span class="label">50 mil+</span>
      <span class="value">Downloads</span>
    </div>
    <div class="desc-item">
      <span class="label"><img src="../assets/unnamed.webp" alt="" /></span>
      <span class="value">Rated for 18+</span>
    </div>
  </div>
</template>
  <script>
import { reactive, toRefs, getCurrentInstance, defineComponent } from "vue";
export default defineComponent({
  name: "",
  components: {},
  setup() {
    const state = reactive({});
    return { ...toRefs(state) };
  },
});
</script>
  <style>
.page-desc {
  justify-content: space-around;
  margin: 16px 0;
  padding: 12px 0;
}
.desc-item {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 16px;
}

.desc-item .label {
  text-align: center;
  color: rgb(32, 33, 36);
  font-size: 14px;
  height: 24px;
  line-height: 24px;
  vertical-align: middle;
  position: relative;
}

.desc-item:not(:first-child)::before {
  background-color: rgb(232, 234, 237);
  content: "";
  display: block;
  height: 24px;
  left: 0;
  position: absolute;
  top: calc(50% - 12px);
  width: 1px;
}

.desc-item:first-child {
  padding-left: 0;
}
</style>
  